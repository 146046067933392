<template>

  <div v-if="!loading && merchantcode" class="af_container">
    <section class="af_b_margin_big">
    <div class="af_flex">

      <div class="af_q&a af_flex af_flex_column af_full_width  af_all_answers">
        <div class="af_row af_question af_flex af_select_question">

          <div class="af_qcontent af_user_question af_w_fixed2 af_left">
            <h2 :style="[$root.textColor,$root.fontH1]" id="text">{{question}}</h2>
            <small :style="[$root.textColor,$root.sizeSmall]" class=" af_text_muted ml-auto af_full_width">Asked on {{created_at}}</small>
            <div class="af_footer_content af_w_fixed2 af_flex af_justify_content_between af_align_items_bottom af_t_margin_sml">
              <router-link rel="nofollow" :style="[$root.primaryColor,$root.sizeLink]" class="af_primary_color" :to="{ name: 'home', params: { merchantcode: merchantcode, sku: sku, domain: domain }}">See all questions about this product</router-link>
            </div>
          </div>
        </div>

        <!-- Section when Answers available -->
        <div class="af_flex_column af_answers af_flex af_all_answers" v-if="allAnswers && !searchLoading">
          <small class=" af_text_muted ml-auto af_full_width af_b_margin" :style="[$root.textColor,$root.sizeSmall]">Showing {{showingFrom}}-{{showingTill}} of {{allRecords}} answers</small>
          <div class="af_acontent af_w_fixed2 af_left af_b_margin_big" v-for="(value, name, index) in allAnswers.data.answers" v-bind:key="index">
            <div class="af_user_answer">

              <!-- Trimmed Answer -->
              <TrimmedAnswer :propAnswer="value.community_answer"></TrimmedAnswer>
              <small class=" af_text_muted ml-auto af_full_width af_t_margin_sml" :style="[$root.textColor,$root.sizeSmall]">By {{value.answered_by_name}} on {{value.created_at}} </small>
              <!-- Helpful component -->
              <Helpful :propanswerID=value.answer_id :propHelpfulCount="value.helpful" :propNothelpfulCount="value.not_helpful"></Helpful>
            </div>
          </div>
          <!-- Paging -->
          <Paging :propTotalRecords=allAnswers.data.totalRecords :propCurrentIndex=this.currentIndex :propPageLimit=this.limit v-on:paginate="paginateSearch"></Paging>
        </div>
        <div v-else style="text-align:center">
          <Loader width="80px"></Loader>
        </div>

      </div>
    </div>
  </section>
    </div>
    <div v-else-if="!merchantcode && !loading">
        Direct access not allowed
    </div>
    <div v-else style="text-align:center">
        <Loader width="120px"></Loader>
    </div>
</template>

<script>
import Helpful from '@/components/Helpful.vue'
import Loader from '@/components/Loader.vue'
import Paging from '@/components/Paging.vue'
import TrimmedAnswer from '@/components/TrimmedAnswer.vue'

export default {
  props: ['qid', 'question', 'created_at', 'merchantcode', 'sku', 'domain'],
  data () {
    return {
      allAnswers: null,
      limit: 5,
      offset: 0,
      loading: true,
      currentIndex: 0,
      searchLoading: false,
      showingFrom: 1,
      showingTill: 5,
      allRecords: 0,
      hideAnswer: true
    }
  },
  methods: {
    paginateSearch: function (offset, index) {
      this.offset = offset
      this.currentIndex = index
      this.searchLoading = true
      this.showingFrom = offset + 1

      this.showingTill = this.maxLimit
      this.displayAnswers()
    },
    displayAnswers: function (event) {
      this.$axios
        .get(this.$siteURL + 'allanswers/' + this.qid + '?api_token=' + this.$root.token, {
          params: {
            offset: this.offset,
            limit: this.limit
          }
        })
        .then(response => {
          this.allRecords = response.data.totalRecords
          this.allAnswers = response
          this.showingTill = this.maxLimit
          this.searchLoading = false
          // this.$Console.log(response)
        })
        .catch(error => {
          this.$Console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    maxLimit: function () {
      let maxLimit = this.limit - 1
      if ((this.showingFrom + maxLimit) > this.allRecords) {
        maxLimit = this.allRecords
      } else {
        maxLimit = this.showingFrom + maxLimit
      }
      return maxLimit
    }
  },
  mounted () {
    this.displayAnswers()
  },
  components: {
    Helpful,
    Loader,
    Paging,
    TrimmedAnswer
  }
}
</script>
